import { createApp } from "/node_modules/.vite/deps/vue.js?v=4fdb2a2a";
import { createPinia } from "/node_modules/.vite/deps/pinia.js?v=4fdb2a2a";
import router from "/src/router/index.js";

import { Quasar } from "/node_modules/.vite/deps/quasar.js?v=4fdb2a2a";
import quasarUserOptions from "/src/quasar/quasar-options.js"; 

import { useUser } from "/src/stores/userStore.js";

import { provideApollo, apolloClient } from "/src/apolloClient.js";
import { gql } from "/node_modules/.vite/deps/@apollo_client_core.js?v=4fdb2a2a";

import App from "/src/App.vue";

import "/src/assets/styles/main.css";

import Nav from "/src/components/nav/Nav.vue";
import RightMenu from "/src/components/rightMenu/RightMenu.vue";
import Header from "/src/components/header/views/Header.vue";
import Alert from "/src/components/alert/Alert.vue";
import Authorization from "/src/features/authorization/views/Authorization.vue";


const app = createApp(App);
provideApollo(app);
app.use(createPinia());
app.use(router);
app.use(Quasar, quasarUserOptions);
app
  .component('Nav', Nav)
  .component('RightMenu', RightMenu)
  .component('Header', Header)
  .component('Alert', Alert)
  .component('Authorization', Authorization)

const userStore = useUser();

const GET_ME_QUERY = gql`
  query AuthQueries {
    authQueries {
      me {
        success
        errorMessage
        data {
          id
        }
      }
    }
  }
`;

async function autoLogin() {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");

  if (!accessToken || !refreshToken) {
    userStore.logout();
    return;
  }

  try {
    const { data, errors } = await apolloClient.query({
      query: GET_ME_QUERY,
    });

    if (errors) {
      console.error("Ошибка авторизации:", errors);
      userStore.logout();
    } else {
      if (data.authQueries.me.success) {
        userStore.setUser(data.authQueries.me.data);
      } else {
        console.error("Ошибка авторизации:", data.authQueries.me.errorMessage);
      }
    }
  } catch (error) {
    console.error("❌ Ошибка Apollo при авторизации:", error);
    userStore.logout();
    
  }
}

autoLogin().then(() => {
  app.mount("#app");
});
